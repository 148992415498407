import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import {
  Grid,
  Box,
  Container,
  Typography,
  Button,
  useTheme,
  Link as MuiLink,
  useMediaQuery,
} from "@mui/material";

import { Social } from "../Social";
import { Logo } from "../Logo";
import { StyledFooter } from "./Footer.styled";
import { myAccountData, orderHistoryData, contactData } from "./Footer.api";

export const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledFooter>
      <Container>
        <Grid container py={4}>
          <Grid item xs={12} md={4}>
            <Box>
              <Typography align={isSmall ? "center" : "left"} color="white">
                {`© ${currentYear} ${data.site.siteMetadata.title}. All rights reserved.`}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent={isSmall ? `center` : `flex-start`}
            >
              <Box>
                <MuiLink
                  component={Link}
                  to={`/privacy-policy`}
                  style={{ textDecoration: "none" }}
                  sx={{ color: "#fff" }}
                >
                  Privacy Policy
                </MuiLink>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent={`center`}>
              <Logo />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              display="flex"
              justifyContent={isSmall ? "center" : "flex-end"}
            >
              <Social />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </StyledFooter>
  );
};
