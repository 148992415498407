import { createSlice } from "@reduxjs/toolkit";
// Define the initial state
export interface AppState {
  currentPath: string;
}

const initialState: AppState = {
  currentPath: "/",
};

export const nav = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setCurrentPath: (state, action) => {
      switch (action.payload) {
        case "/about-mj":
          state.currentPath = "/mj";
          break;
        case "/philosophy":
          state.currentPath = "/mj";
          break;
        case "/agency":
          state.currentPath = "/sports";
          break;
        case "/equine-spirit":
          state.currentPath = "/sports";
          break;
        default:
          state.currentPath = action.payload;
          break;
      }
    },
  },
});

export const { setCurrentPath } = nav.actions;

export default nav.reducer;
