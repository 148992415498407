import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  AppBar,
  Container,
  ButtonBase,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme,
  useScrollTrigger,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { navigate } from "gatsby";
import { VerticalAlignCenter } from "../VerticalAlignCenter";
import { Logo } from "../Logo";
import { setCurrentPath } from "../../store/reducers/nav.reducer";

import { navigation } from "./Header.api";

export const Header: React.FC = () => {
  const { currentPath } = useSelector((state: any) => state.navReducer);

  const dispatch = useDispatch();
  const theme = useTheme();
  const [bgColor, setBgColor] = useState("transparent");
  const trigger = useScrollTrigger();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const appBarHeight = 75;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  const handleClickNavigate = (route: string) => {
    navigate(route);
    setDrawerOpen(false);
    handleCloseDropdown();
    dispatch(setCurrentPath(route));
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
    handleCloseDropdown();
  };

  const handleOpenDropdown = (
    event: React.MouseEvent<HTMLElement>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenDropdownId(id);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setOpenDropdownId(null);
  };

  const renderDropdownMenu = (item) => {
    return (
      <Menu
        id={item.id}
        anchorEl={anchorEl}
        open={openDropdownId === item.id}
        onClose={handleCloseDropdown}
      >
        {item.dropdown.map((dropdownItem) => (
          <MenuItem
            key={dropdownItem.id}
            onClick={() => handleClickNavigate(dropdownItem.route)}
          >
            {dropdownItem.title}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  const renderDrawer = () => (
    <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
      <List>
        {navigation.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton onClick={() => handleClickNavigate(item.route)}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  useEffect(() => {
    setBgColor(trigger ? "rgba(0,0,0,0.9)" : "#000");
  }, [trigger]);

  return (
    <Box sx={{ flexGrow: 1 }} pt={1}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: bgColor, transition: "background-color 0.3s" }}
        elevation={0}
      >
        <Container>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <VerticalAlignCenter height={appBarHeight}>
                <ButtonBase onClick={() => handleClickNavigate("/")}>
                  <Logo />
                </ButtonBase>
              </VerticalAlignCenter>
            </Box>

            {isMobile ? (
              <IconButton
                color="inherit"
                edge="end"
                onClick={handleDrawerToggle}
              >
                <MenuIcon color="primary" />
              </IconButton>
            ) : (
              <Box>
                <VerticalAlignCenter height={appBarHeight}>
                  {" "}
                  <Box display="flex">
                    {navigation.map((item) => (
                      <Box ml={2} key={item.id}>
                        {item.dropdown ? (
                          <React.Fragment>
                            <Button
                              color="secondary"
                              onClick={(e) => handleOpenDropdown(e, item.id)}
                              sx={{
                                color:
                                  currentPath === item.route
                                    ? theme.palette.common.white
                                    : "default",
                                borderBottom:
                                  currentPath === item.route
                                    ? `2px solid ${theme.palette.secondary.main}`
                                    : "none",
                              }}
                            >
                              {item.title}
                            </Button>
                            {renderDropdownMenu(item)}
                          </React.Fragment>
                        ) : (
                          <Button
                            color="secondary"
                            onClick={() => handleClickNavigate(item.route)}
                            sx={{
                              color:
                                currentPath === item.route
                                  ? theme.palette.common.white
                                  : "default",
                              borderBottom:
                                currentPath === item.route
                                  ? `2px solid ${theme.palette.secondary.main}`
                                  : "none",
                            }}
                          >
                            {item.title}
                          </Button>
                        )}
                      </Box>
                    ))}
                  </Box>
                </VerticalAlignCenter>
              </Box>
            )}
          </Box>
        </Container>
      </AppBar>
      {isMobile && renderDrawer()}
    </Box>
  );
};
