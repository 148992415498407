import React from "react";
import { uid } from "uid";
import { IconButton } from "@mui/material";
// import GitHubIcon from "@mui/icons-material/GitHub"
import InstagramIcon from "@mui/icons-material/Instagram";
// import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";

interface SocialData {
  id: string;
  url: string;
  icon: keyof typeof SocialIconMap;
  title: string;
}

const SocialIconMap = {
  linkedin: LinkedInIcon,
  instagram: InstagramIcon,
  facebook: FacebookIcon,
};

const socialData: SocialData[] = [
  {
    id: uid(),
    url: "https://instagram.com/",
    icon: "instagram",
    title: "Instagram",
  },
  {
    id: uid(),
    url: "https://www.facebook.com/",
    icon: "facebook",
    title: "Facebook",
  },
  {
    id: uid(),
    url: "https://www.linkedin.com/",
    icon: "linkedin",
    title: "LinkedIn",
  },
];

export const Social: React.FC = ({ ...props }) => {
  return (
    <>
      {socialData.map(({ id, url, icon }) => (
        <IconButton
          href={url}
          target="_blank"
          rel="noreferrer"
          key={id}
          size="large"
          sx={{ color: "#fff" }}
        >
          {React.createElement(SocialIconMap[icon])}
        </IconButton>
      ))}
    </>
  );
};
