import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import LogoImg from "../../images/logo.svg";

export const Logo: React.FC = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="img"
      src={LogoImg}
      height={isSmall ? `40px` : `40px`}
      alt="wutif logo"
    />
  );
};
