import React from "react";
import { Box } from "@mui/material";

import { Header } from "../Header";
import { Footer } from "../Footer";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Box display="flex" flexDirection={`column`} height={`100vh`}>
      <Header />
      <Box component={`main`} flexGrow={1}>
        <Box>{children}</Box>
      </Box>
      <Footer />
    </Box>
  );
};
