import { createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import { primaryColor, secondaryColor } from "./colors";
import { bodyFont, fontFamilies, headlineFont } from "./fonts";

// A custom theme for this app
export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  typography: {
    fontFamily: fontFamilies,
    h1: {
      fontFamily: headlineFont,
      fontWeight: 700,
    },
    h2: {
      fontFamily: headlineFont,
      fontWeight: 700,
    },
    h3: {
      fontFamily: headlineFont,
      fontWeight: 700,
    },
    h4: {
      fontFamily: headlineFont,
      fontWeight: 700,
    },
    h5: {
      fontFamily: headlineFont,
      fontWeight: 700,
    },
    h6: {
      fontFamily: headlineFont,
      fontWeight: 700,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontFamily: headlineFont,
        },
      },
    },
  },
});
