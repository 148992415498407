import { uid } from "uid";
import {
  HOME,
  ABOUT_MJ,
  HAND_SURGERY,
  CONTACT,
  BOOKS_MAGS,
  GO_TOUCHLESS,
  FASHION,
  AGENCY,
  SPORTS_MANAGEMENT,
  MEDIATION,
} from "../../constants/routes";

export const navigation = [
  { id: uid(), title: "Home", route: HOME },
  { id: uid(), title: "About", route: ABOUT_MJ },
  { id: uid(), title: "Hand Surgery", route: HAND_SURGERY },
  {
    id: uid(),
    title: "Sports Coaching",
    route: null,
    dropdown: [
      {
        id: uid(),
        title: "Condition Sports Management",
        route: SPORTS_MANAGEMENT,
      },
      {
        id: uid(),
        title: "MJMD Mediation",
        route: MEDIATION,
      },
    ],
  },
  { id: uid(), title: "Book Series", route: BOOKS_MAGS },
  { id: uid(), title: "Tableware", route: GO_TOUCHLESS },
  // { id: uid(), title: "Golf Fasion", route: FASHION },
  { id: uid(), title: "Contact", route: CONTACT },
];
